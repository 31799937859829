import React, { Component } from 'react'
import Layout from '../components/layout'
import styled from 'styled-components'
import { Link as GatsbyLink, graphql } from 'gatsby'
import get from 'lodash/get'

import { media } from '../themes/index'

import { Title, Main } from '../components/styles/pages'

const Index = styled.ul`
  display: flex;
  flex-wrap: wrap;

  width: 80vw;
  max-width: 920px;
  margin: 0 auto;

  li {
    width: 50%;
    text-align: center;
    list-style: none;
    font-size: 2rem;
    font-weight: 300;
    line-height: 2rem;
    margin: 2rem 0;

    ${media.tablet`
      width: 100%;
    `}
  }

  ${media.tablet`
      display: block;
      width: 100%;
  `}
`

const SubTitle = styled.span`
  display: block;
  font-size: 1.8rem;
  color: #999;
`

const Link = styled(GatsbyLink)`
  text-decoration: none;
  color: #000;
`

class PapersPage extends Component {
  render() {
    const { pageResources } = this.props

    const papers = get(this.props, 'data.allSanityPaper.edges', [])

    return (
      <>
        <Layout pageResources={pageResources}>
          <Main>
            <Title>Scritti</Title>

            <Index>
              {papers &&
                papers.map(({ node: { title, author, slug } }) => {
                  const slugPath = slug && slug.current

                  return (
                    <li key={slugPath}>
                      <Link to={`/papers/${slugPath}`}>
                        {title} {author && <SubTitle>{author}</SubTitle>}
                      </Link>
                    </li>
                  )
                })}
            </Index>
          </Main>
        </Layout>
      </>
    )
  }
}

export default PapersPage

export const query = graphql`
  query {
    allSanityPaper(sort: { fields: author, order: ASC }) {
      edges {
        node {
          id
          title
          author
          slug {
            _type
            current
          }
        }
      }
    }
  }
`
